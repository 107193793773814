var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { viewBox: "0 0 35.7 46.1", width: "1em", height: "1em" } },
    [
      _c("path", {
        attrs: {
          d:
            "M29.7,26l-4.9-3.6l4.9-3.7V8.3L21.4,0l-0.1,15.3l-3.5,2.4l-3.5-2.4L14.3,0L6,8.3v10.5l4.9,3.7L6,26L0,46.1h4.8l13-17.8l13,17.8h4.8L29.7,26z M17.9,24.8l-2.4-2.4l2.3-2.3l2.4,2.4L17.9,24.8z",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }